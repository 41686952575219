import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash-es';

// Connects to data-controller="otp-submit"
export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.inputTarget.addEventListener(
      'keyup',
      debounce(this.submit.bind(this), 300)
    );
  }

  submit() {
    const input = this.inputTarget.value;

    if (input.length === 6) {
      this.element.submit();
    }
  }
}
